/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useNotifications } from '@/data/Content/Notifications';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { getLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { PASSWORD_EXPIRED } from '@/data/constants/errors';
import { ID, TransactionErrorResponse } from '@/data/types/Basic';
import { ContentProps } from '@/data/types/ContentProps';
import { ErrorType } from '@/data/types/Error';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { isErrorType, processError } from '@/data/utils/processError';
import cookieCutter from '@boiseitguru/cookie-cutter';
import { transactionsLoginIdentity, transactionsPerson } from 'integration/generated/transactions';
import {
	ComIbmCommerceRestMemberHandlerLoginIdentityHandlerLoginForm,
	ComIbmCommerceRestMemberHandlerLoginIdentityHandlerUserIdentity,
} from 'integration/generated/transactions/data-contracts';
import { RequestParams } from 'integration/generated/transactions/http-client';
import { useState } from 'react';

export { personMutatorKeyMatcher } from '@/data/utils/mutatorKeyMatchers/personMutatorKeyMatcher';

export const loginFetcher =
	(pub: boolean) =>
	async (
		storeId: string,
		query: {
			[key: string]: string | boolean | ID[] | number;
		},
		data: ComIbmCommerceRestMemberHandlerLoginIdentityHandlerLoginForm,
		params: RequestParams
	) =>
		await transactionsLoginIdentity(pub).loginIdentityLogin(storeId, query, data, params);

export type UserLogon = {
	logonId?: string;
	/**
	 * @deprecated do not use.
	 */
	email?: string;
	logonPassword: string;
	rememberMe: boolean;
	logonPasswordNew?: string;
	logonPasswordVerify?: string;
};

const userFetcher =
	(pub: boolean) =>
	async ({
		storeId,
		langId,
		params = {},
	}: {
		storeId: string;
		params?: RequestParams;
		langId?: string;
	}) => {
		try {
			const personData = await transactionsPerson(pub).personFindPersonBySelf(
				storeId,
				{ langId } as any,
				params
			);
			return personData;
		} catch (error) {
			if (pub) {
				throw error;
			} else {
				console.log(error);
			}
		}
	};

export const getLogin = async ({ cache, context }: ContentProps) => {
	await Promise.all([
		getLocalization(cache, context.locale || 'en-US', 'SignIn'),
		getLocalization(cache, context.locale || 'en-US', 'RegistrationB2BLayout'),
	]);
};

export const useLogin = () => {
	const { settings } = useSettings();
	const router = useNextRouter();
	const { storeId, langId } = getClientSideCommon(settings, router);
	const params = useExtraRequestParameters();
	const { notifyError } = useNotifications();
	const [passwordExpired, setPasswordExpired] = useState<(ErrorType & { user: UserLogon }) | null>(
		null
	);

	const setJSPCookies = async (
		resp: ComIbmCommerceRestMemberHandlerLoginIdentityHandlerUserIdentity
	) => {
		if (resp?.userId) {
			// set WC_DisplayContractPanel cookie for JSP store
			cookieCutter.set('WC_DisplayContractPanel_' + storeId, 'false', {
				path: '/',
				domain: '',
			});
			// set WC_LogonUserId cookie for JSP store
			const userResp = await userFetcher(true)({ storeId, langId });
			const displayName = userResp?.firstName ?? userResp?.lastName;
			if (displayName) {
				cookieCutter.set('WC_LogonUserId_' + storeId, displayName, {
					path: '/',
					domain: '',
				});
			}
		}
	};

	const loginSubmit = async (props: UserLogon) => {
		const { email = '', logonId: _logonId, rememberMe, ...rest } = props;
		const logonId: string = _logonId || email;
		try {
			const resp = await loginFetcher(true)(
				settings?.storeId ?? '',
				{ rememberMe: props.rememberMe, updateCookies: 'true' },
				{ logonId, ...rest },
				params
			);
			setJSPCookies(resp);
			return resp;
		} catch (e) {
			const error: TransactionErrorResponse | ErrorType = processError(
				e as TransactionErrorResponse
			);
			if (isErrorType(error) && error.error.errorKey === PASSWORD_EXPIRED) {
				setPasswordExpired({ ...error, user: { ...props } });
			} else {
				notifyError(error);
			}
			return error;
		}
	};

	return {
		loginSubmit,
		passwordExpired,
		setPasswordExpired,
	};
};
