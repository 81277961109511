import { ThemeOptions } from '@mui/material/styles';

/* Relative path to upload folder will not work in development */
const path =
	process.env.NODE_ENV === 'production'
		? '/wcsstore/WacoalStorefrontAssetStore/upload/css/font/'
		: '/fonts/';

export const fonts: ThemeOptions = {
	fonts: [
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 300,
			src: `url(${path}ProximaNova-Light-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Light-webfont.woff) format("woff")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}ProximaNova-Reg-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Reg-webfont.woff) format("woff")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 600,
			src: `url(${path}ProximaNova-Sbold-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Sbold-webfont.woff) format("woff")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 700,
			src: `url(${path}ProximaNova-Bold-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Bold-webfont.woff) format("woff")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'ProximaNova',
			style: 'normal',
			display: 'swap',
			weight: 800,
			src: `url(${path}ProximaNova-Xbold-webfont.ttf) format("truetype"),
						url(${path}ProximaNova-Xbold-webfont.woff) format("woff")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},

		{
			family: 'BentonMod',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}BentonModDisp-Regular.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'BentonMod',
			style: 'italic',
			display: 'swap',
			weight: 400,
			src: `url(${path}BentonModDisp-RegularIt.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'BentonMod',
			style: 'normal',
			display: 'swap',
			weight: 700,
			src: `url(${path}BentonModDisp-Bold.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
		{
			family: 'BentonMod',
			style: 'italic',
			display: 'swap',
			weight: 800,
			src: `url(${path}BentonModDisp-BlackIt.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},

		{
			family: 'Operetta52',
			style: 'normal',
			display: 'swap',
			weight: 300,
			src: `url(${path}Operetta52-Light.otf) format("opentype")`,
		},
		{
			family: 'Modesticdisplay-Bold',
			style: 'normal',
			display: 'swap',
			weight: 900,
			src: `url(${path}Modesticdisplay-Bold.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},

		{
			family: 'Modesticdisplay-BoldItalic',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}Modesticdisplay-BoldItalic.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},

		{
			family: 'QTAgateType-Bold',
			style: 'normal',
			display: 'swap',
			weight: 900,
			src: `url(${path}QTAgateType-Bold.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},

		{
			family: 'QTAgateTypeMedium',
			style: 'normal',
			display: 'swap',
			weight: 400,
			src: `url(${path}QTAgateTypeMedium.otf) format("opentype")`,
			unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
		},
	],
};
